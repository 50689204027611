import React, { useEffect, useState } from 'react';
import './App.css';

function App() {

    const [webDescription, setWebDescription] = useState('');
    const [botDescription, setBotDescription] = useState('');
    const [mobDescription, setMobDescription] = useState('');

    const [isFocused, setIsFocused] = useState(false);

    // Обработчики событий для наведения и ухода мыши
    const handleMouseEnter = () => {
        setIsFocused(true);
    };

    const handleMouseLeave = () => {
        setIsFocused(false);
    };

    const handleMouseEnterWeb = () => {
        setWebDescription("Мы предлагаем решения, которые подходят как для простых, так и для сложных задач. Наша современная архитектура обеспечивает высокую скорость и эффективность. Мы специализируемся на автоматизации бизнес-процессов, чтобы ваша компания могла работать более эффективно и продуктивно.");
    };

    const handleMouseLeaveWeb = () => {
        setWebDescription('');
    };

    const handleMouseEnterBot = () => {
        setBotDescription("Описание ботов");
    };

    const handleMouseLeaveBot = () => {
        setBotDescription('');
    };

    const handleMouseEnterMob = () => {
        setMobDescription("Описание мобильных приложений");
    };

    const handleMouseLeaveMob = () => {
        setMobDescription('');
    };

    const handleMouseClick = () => {
        document.querySelectorAll('.expanded').forEach(element => {
            element.classList.remove('expanded');
        });
        const appsSection = document.querySelector('.apps');
        appsSection.classList.toggle('expanded');
    };

    const handleMouseClickBot = () => {
        document.querySelectorAll('.expanded').forEach(element => {
            element.classList.remove('expanded');
        });
        const appsSection = document.querySelector('.bots');
        appsSection.classList.toggle('expanded');
    };

    const handleMouseClickSite = () => {
        document.querySelectorAll('.expanded').forEach(element => {
            element.classList.remove('expanded');
        });
        const appsSection = document.querySelector('.site');
        appsSection.classList.toggle('expanded');
    };



    useEffect(() => {
        // Присваиваем обработчик события клика секции
        document.querySelector('.site').addEventListener('click', handleMouseClickSite);
        document.querySelector('.bots').addEventListener('click', handleMouseClickBot);
        document.querySelector('.apps').addEventListener('click', handleMouseClick);
    }, []);

    const handleClickSend = async () => {
        const contact = document.getElementById('contact').value;
        const task = document.getElementById('task').value;
        console.log('send', contact, task)

        // Формируем данные для отправки
        const data = {
            contact: contact,
            task: task
        };

        // Отправляем POST-запрос на сервер
        const response = await fetch('https://valdex.na4u.ru/addlid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        // Получаем ответ от сервера
        const result = await response.json();

        // Делаем что-то с результатом, например, выводим его в консоль
        console.log(result);
    }
    return (
        <div className="app">
            <header className="header">
                {/* <div className="word srg">SRG</div>
                <div className="line"></div>
                <div className="word dimmed">spark</div>
                <div className="line"></div>
                <div className="word dimmed">revolution</div>
                <div className="line"></div>
                <div className="word dimmed">genesis</div> */}
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <div class="big">D</div>
                    <div class="small orange">ev</div>
                    <div class="big dark-gray">F</div>
                    <div class="small green">ast</div>
                </div>
            </header>
            <main className="main">
                <section className="site" onMouseEnter={handleMouseEnterWeb} onMouseLeave={handleMouseLeaveWeb}>
                    <h2>Сайты</h2>
                    {webDescription &&
                        <div className="service-description" style={{ textAlign: "left", color: "#858AE3" }}>
                            Мы предлагаем решения, которые подходят как для простых, так и для сложных задач<br /><br />
                            Cовременная архитектура обеспечивает высокую скорость и эффективность<br /><br />
                            Автоматизации бизнес-процессов увеличивает продуктивность и прибыль
                        </div>
                    }
                </section>
                <section className="bots" onMouseEnter={handleMouseEnterBot} onMouseLeave={handleMouseLeaveBot}>
                    <h2>Боты</h2>
                    {botDescription &&
                        <div className="service-description" style={{ textAlign: 'left', color: '#858AE3' }}>
                            Передовые инструменты для автоматизации рутиных задач<br /><br />
                            Высокая скорость<br /><br />
                            Интеграция в популярные платформы
                        </div>
                    }

                </section>
                <section className="apps" onMouseEnter={handleMouseEnterMob} onMouseLeave={handleMouseLeaveMob}>
                    <h2>Мобильные приложения</h2>
                    {mobDescription &&
                        <div className="service-description" style={{ textAlign: 'left', color: '#858AE3' }}>
                            Создаем качественные мобильные приложения для платформ Android и iOS<br /><br />
                            Пользовательский опыт высокого уровня<br /><br />
                            Поддержка всех современных устройств и версий операционных систем
                        </div>
                    }
                </section>
            </main>

            <section className="feedback-form">
                <h3>Свяжитесь с нами</h3>
                <div>
                    <div class="form-group">
                        <label htmlFor="contact" className={isFocused ? 'label-focus' : ''} >
                            Контакт
                        </label>
                        <input
                            type="text"
                            id="contact"
                            name="contact"
                            className="input-contact"
                            placeholder="Введите контакт"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="task" className={isFocused ? 'label-focus' : ''}>Задача</label>
                        <textarea id="task" name="task" placeholder="Введите вашу задачу" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}></textarea>
                    </div>
                    <div className="form-group cntr-row j-e">

                        <button onClick={handleClickSend}>Отправить</button>
                    </div>
                </div>
            </section>


            <div className="background">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-35 -18 205 15">
                    {/* Овалы */}
                    <defs>
                        {/* <linearGradient id="planetGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#cbaba0" />
                            <stop offset="100%" stopColor="#fe5d26" />
                        </linearGradient> */}
                        <radialGradient id="planetGradient" cx="10%" cy="70%" r="50%" fx="50%" fy="50%">
                            <stop offset="0%" stopColor="#FFFFFF" />
                            <stop offset="100%" stopColor="#fe5d26" />
                        </radialGradient>
                    </defs>
                    <ellipse cx="40" cy="10" rx="60" ry="15" fill="none" stroke="url(#planetGradient)" strokeWidth="0.1" transform="rotate(37, 40, 10)" />
                    <ellipse cx="40" cy="10" rx="48" ry="12" fill="none" stroke="url(#planetGradient)" strokeWidth="0.1" transform="rotate(37, 40, 10)" />
                    <ellipse cx="40" cy="10" rx="36" ry="9" fill="none" stroke="url(#planetGradient)" strokeWidth="0.1" transform="rotate(37, 40, 10)" />


                    {/* Круги */}


                    <circle cx="21" cy="-11" r="2" fill="#FF7E4B" />
                    <circle cx="4" cy="-20" r="1" fill="#FF7E4B" />


                </svg>
            </div>
        </div>
    );
}

export default App;
